import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  background-color: #f0f0f0;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  @media (max-width: 768px) {
    height:auto;
    min-height: 80vh;
    max-height: fit-content;
  }
    `

export const H1 = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  `

export const Line = styled.div`
  width: calc(100% + 40px);
  height: 1px;
  background-color: #ccc;
  margin-left: -20px;
  `

export const Cabecalho = styled.div`
  display: flex;
  flex-direction: column;
  `

export const Row = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`
export const Corpo = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  height: 85%;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  `


export const H2 = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
  padding-bottom: 10px;
  text-align: center;
  `

export const H3 = styled.h3`
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
  padding-bottom: 10px;
  width: 70%;
  text-align: center;
  `

export const Button = styled.button`
  background-color: #019863;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #017c52;
  }

  &:focus {
    outline: none;
  }
`;

export const BlocoImagem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 30%;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
  `

export const BlocoResultado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 70%;
  height: 100%;
    @media (max-width: 768px) {
    width: 100%;
  }
  `


export const PageWrapper = styled.div`
  font-family: 'Segoe UI', Arial, sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
  margin: 0;
  padding: 40px;
  min-height: 100vh;
`;

export const Header = styled.header`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #eef2f5;
`;

export const HeaderLeft = styled.div`
  h1 {
    color: #2d3748;
    margin: 0;
    font-size: 28px;
  }
`;

export const HeaderRight = styled.div`
  text-align: right;
  color: #718096;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const ImageCard = styled.div`
  background: #f8fafc;
  padding: 20px;
  border-radius: 15px;
  text-align: center;

  h3 {
    color: #4a5568;
    margin-top: 0;
    margin-bottom: 15px;
  }

  img {
    border-radius: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const Sidebar = styled.aside`
  background: #f8fafc;
  border-radius: 15px;
  padding: 1rem;
  `;

export const ResultBox = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 2px solid ${props => props.status === 'NEGATIVE' ? '#38a169' : '#dc3545'};
  `;

//passa props
export const Status = styled.p`
  color: ${props => props.status === 'NEGATIVE' ? '#38a169' : '#dc3545'};
  font-weight: bold;
  font-size: 20px;
  margin: 0;
`;

export const Confidence = styled.p`
  font-size: 18px;
  color: #4a5568;
  margin: 10px 0;
`;

export const ConfidenceBar = styled.div`
  width: 100%;
  height: 8px;
  background: #e2e8f0;
  border-radius: 4px;
  margin-top: 10px;
`;

export const ConfidenceFill = styled.div`
  width: ${props => props.percentage}%;
  height: 100%;
  background: ${props => props.status === 'NEGATIVE' ? '#38a169' : '#dc3545'};
  border-radius: 4px;
  transition: width 1s ease;
`;

export const Details = styled.div`
  h3 {
    color: #2d3748;
    margin-top: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 12px 0;
    border-bottom: 1px solid #e2e8f0;
    color: #4a5568;
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const Recommendation = styled.div`
  background: ${props => props.status === 'NEGATIVE' ? '#38a169' : '#dc3545'};
  padding: 20px;
  border-radius: 12px;
  margin-top: 20px;
  h3 {
    color: ${props => props.status === 'NEGATIVE' ? '#fff' : '#fff'};
    margin-top: 0;
    font-weight: bold;
    padding-bottom: 20px;
    }
  p {
    color: ${props => props.status === 'NEGATIVE' ? '#fff' : '#fff'};
  }
`;
export const Imagem = styled.img`
  width: 100%;
  height: auto;
@media (max-width: 768px) {
  width: 70%;
  padding: 20px;
  `

  
export const InfoSection = styled.div`
padding: 2rem;
border-radius: 10px;
width: 100%;
margin-top: auto;
`;

export const InfoTitle = styled.h2`
color: #333;
font-size: 1.5rem;
margin-bottom: 1.5rem;
text-align: center;
`;

export const InfoGrid = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
gap: 2rem;
`;

export const InfoCard = styled.div`
background: white;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
padding: 1rem;
border-radius: 8px;
`;

export const InstituteTitle = styled.h3`
color: #49beb7;
font-size: 1.2rem;
margin-bottom: 1rem;
`;

export const ContactInfo = styled.div`
margin: 0.5rem 0;
display: flex;
flex-direction: row;
justify-content: space-between;
a {
  color: #49beb7;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
`;
