import { Container, H1, Line, Cabecalho, Row, Corpo, BlocoImagem, BlocoResultado, Sidebar, ResultBox, Status, Confidence, ConfidenceBar, ConfidenceFill, Details, Recommendation, Imagem ,InfoSection, InfoGrid, InfoCard, InstituteTitle, ContactInfo} from './styles'
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
const MainContainer = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); // Redireciona para a página anterior
  };
  const location = useLocation();
  let { prediction, probability, image } = location.state || {};
  let error = (100 - (probability * 100)).toFixed(2);
  let prob = ((probability * 100).toFixed(2));
  
  let status = 'NEGATIVE';
  if (prediction === "Câncer") {
    status = 'POSITIVE';
  }
  return (
    <Container>
      <Cabecalho>
        <Row>
        <FaArrowLeft onClick={goBack} style={{cursor: "pointer", fill: "#38a169", padding:"3px"}}/>
          <H1>Resultado da Análise da Imagem</H1>
        </Row>
        <Line />
      </Cabecalho>
      <Corpo>
        <BlocoImagem>
        <Imagem
          src={image || "/api/placeholder/600/400"} 
          alt="Imagem original da análise" 
        />
        </BlocoImagem>
        <BlocoResultado>
        <Sidebar>
          <ResultBox status={status}>
            <Status status={status}>{prediction === "Normal" ? "NEGATIVO" : "POSITIVO"} PARA CÉLULAS CANCERÍGENAS</Status>
            <Confidence>Confiança da análise: {prob}%</Confidence>
            <ConfidenceBar>
              <ConfidenceFill percentage={prob} status={status}/>
            </ConfidenceBar>
          </ResultBox>

          <Details>
            <h3>Detalhes Importantes da Análise</h3>
            <ul>
              <li> Essa análise foi feita com base em uma única imagem, é aconselhável que sejam feitas mais análises para um diagnóstico mais preciso.</li>
              <li> Essa ferramenta NÃO substitui a avaliação de um profissional da saúde.</li>
              <li> A análise foi feita com base em um modelo de Machine Learning treinado com imagens de câncro bucal, logo, erros podem ocorrer.</li>
              <li> A confiança da análise é de {prob}%, ou seja, a probabilidade de erro é de {error}%.</li>
            </ul>

            <Recommendation status={status}>
              <h3>Recomendação</h3>
              {
                prediction === "Normal" ? (
                  <p> Se houver suspeita de câncer bucal, procure um profissional da saúde para uma avaliação mais precisa.</p>
                ) : (
                  <p> Encaminhar para um profissional da saúde para uma avaliação mais detalhada.</p>
                )
              }
            </Recommendation>
          </Details>
        </Sidebar>
        </BlocoResultado>
      </Corpo>
      <InfoSection>
        <InfoGrid>
          <InfoCard>
            <InstituteTitle>Instituto Nacional de Câncer (INCA)</InstituteTitle>
            <ContactInfo>
              <p>📞 (21) 3207-1000</p>
              <p>🌐 <a href="https://www.inca.gov.br" target="_blank" rel="noopener noreferrer">www.inca.gov.br</a></p>
            </ContactInfo>
          </InfoCard>

          <InfoCard>
            <InstituteTitle>Instituto Oncoguia</InstituteTitle>
            <ContactInfo>
              <p>📞 (11) 2503-7890</p>
              <p>🌐 <a href="https://www.oncoguia.org.br" target="_blank" rel="noopener noreferrer">www.oncoguia.org.br</a></p>
            </ContactInfo>
          </InfoCard>

          <InfoCard>
            <InstituteTitle>Instituto Avon - Ligue Câncer</InstituteTitle>
            <ContactInfo>
              <p>📞 0800 773 1666</p>
              <p> 🌐 <a href="https://institutoavon.org.br/liguecancer/" target="_blank" rel="noopener noreferrer"> www.institutoavon.org.br</a> </p>
            </ContactInfo>
          </InfoCard>
        </InfoGrid>
      </InfoSection>


    </Container>
  )
}

export default MainContainer
