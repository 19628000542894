import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-height: 80vh;
  height: auto;
  background-color: #f0f0f0;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  `

export const H1 = styled.h1`
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  `

export const Line = styled.div`
  width: calc(100% + 40px);
  height: 1px;
  background-color: #ccc;
  margin-left: -20px;
  `

export const Cabecalho = styled.div`
  display: flex;
  flex-direction: column;
  `

export const Row = styled.div`
display: flex;
flex-direction: row;
gap: 5px;
`
export const Corpo = styled.div`
margin-top: 3rem;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  height: 80%;
  justify-content: center;
  gap: 20px;
  `

export const H2 = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
  padding-bottom: 10px;
  text-align: center;
  `

export const H3 = styled.h3`
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
  padding-bottom: 10px;
  width: 70%;
  text-align: center;
  `

export const Button = styled.button`
  background-color: #019863;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #017c52;
  }

  &:focus {
    outline: none;
  }
`;

export const InfoSection = styled.div`
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  margin-top: auto;
  `;

export const InfoTitle = styled.h2`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

export const InfoCard = styled.div`
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem;
  border-radius: 8px;
`;

export const InstituteTitle = styled.h3`
  color: #49beb7;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

export const ContactInfo = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a {
    color: #49beb7;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;