import { Container } from './styles'
import MainContainer from './components/mainContainer'
const Home = () => {
  return (
    <Container>
      <MainContainer/>
    </Container>
  )
}

export default Home
